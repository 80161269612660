import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/Seo'

export default function Template({ data }) {
    const post = data.markdownRemark

    return (
        <Layout>
            <SEO title="Notes" />
                <div className="border border-primary-200 rounded-sm p-4 w-100 mt-24 mb-10 mx-auto md:mt-28 md:w-3/4 xl:w-3/5">
                    <h1 className="font-bold mb-2"><span style={highlightText}>{post.frontmatter.title}</span></h1>
                    <h4 className="text-sm">Posted on {post.frontmatter.date} </h4>
                    <p className="mt-2 text-sm">{post.frontmatter.description} </p>
                    <div className="mt-2" dangerouslySetInnerHTML={{ __html: post.html }} />
                    <div className="flex flex-wrap mt-4">
                        {post.frontmatter.tags.map((tag,i) => (
                            <span key={i} className="flex-grow-0 text-sm rounded-full py-1 px-2 mx-1 md:mx-0 bg-primary-50">
                                {tag}
                            </span>
                        ))}
                    </div>
                </div>
        </Layout>
            
    )
}

export const postQuery = graphql`
    query NotesByPath($path: String!){
        markdownRemark(frontmatter: { path: {eq: $path}}){
            html
            frontmatter {
                path
                title
                tags
                date
                description
            }
        }
    }
`

const highlightText = {
    background: "linear-gradient(to top, #ffff00cb 50%, transparent 50%)"
}